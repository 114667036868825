import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { GlobalsService } from '@app/core';
import { CommonModule, NgIf } from '@angular/common';
import { FullLoaderComponent } from './shared';
declare let window: any;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [FullLoaderComponent, RouterOutlet, CommonModule, NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  globals: GlobalsService

  constructor() {
    // window.defaultTable()
    this.globals = inject(GlobalsService);
  }
}
