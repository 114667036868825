import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PreloadAllModules, provideRouter, withComponentInputBinding, withPreloading } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { NetworkInterceptor, RequestInterceptorService, CachingInterceptor, GlobalErrorHandlerService, GlobalsService, StorageServices, RequestService, firebaseConfig, FirebaseDbService, ToasterService } from '@app/core';
import { CurrencyPipe, DatePipe } from '@angular/common';


export function init(
  globals: GlobalsService, 
) {
  return async () => { 
    try {
      await globals.initializeApp()
    } catch (error) {
      console.log(error)
    }
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    RequestService, FirebaseDbService, ToasterService, StorageServices, GlobalsService, 
    CurrencyPipe, DatePipe,
    { 
      provide: APP_INITIALIZER, 
      useFactory: init,
      deps: [ GlobalsService ], 
      multi: true
    },
    importProvidersFrom(BrowserAnimationsModule),
    provideRouter(
      routes, 
      withPreloading(PreloadAllModules), 
      withComponentInputBinding()
    ),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: NetworkInterceptor, 
      multi: true 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
  ]
};
